import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/code/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Hitabis GmbH`}</h2>
    <p>{`Hildegard-Jadamowitz-Straße 26`}<br parentName="p"></br>{`
`}{`10243 Berlin`}</p>
    <h2>{`Geschäftsführung`}</h2>
    <p>{`Claudia Braese`}<br parentName="p"></br>{`
`}{`Michael Hagedorn`}<br parentName="p"></br>{`
`}{`Heiko Thiele`}<br parentName="p"></br>{`
`}{`Katrin Thielemann  `}</p>
    <p>{`USt-Id: DE239472179`}<br parentName="p"></br>{`
`}{`HRB 95959`}<br parentName="p"></br>{`
`}{`AG Charlottenburg  `}</p>
    <p>{`Plattform der EU-Kommission zur Online-Streitbeilegung: `}<a parentName="p" {...{
        "href": "https://ec.europa.eu/consumers/odr"
      }}>{`https://ec.europa.eu/consumers/odr`}</a></p>
    <h2>{`Haftungsausschluss`}</h2>
    <p>{`Diese Website bildet den temporären Stand eines kontinuierlichen Arbeitsprozesses ab. Obwohl bei der Zusammenstellung der auf unseren Webseiten enthaltenen Informationen größte Sorgfalt angewandt wurde, kann der Betreiber für die Aktualität, Richtigkeit oder Vollständigkeit keine Gewähr übernehmen. Haftungsansprüche gegen den Betreiber, die sich auf Schäden materieller oder ideeller Art beziehen, welche durch die Nutzung oder Nichtnutzung der dargebotenen Informationen oder durch fehlerhafte und unvollständige Informationen verursacht wurden, sind grundsätzlich ausgeschlossen. Wir behalten uns Änderungen der Informationen auf unserem Internetangebot ohne vorherige Ankündigung vor. Alle Angebote sind freibleibend und unverbindlich.`}</p>
    <h2>{`Marken- und Warenzeichen`}</h2>
    <p>{`Alle innerhalb dieser Website genannten Marken- und Warenzeichen unterliegen uneingeschränkt den Bestimmungen des jeweils gültigen Kennzeichnungsrechtes und gegebenenfalls den Besitzrechten der jeweiligen eingetragenen Eigentümer.
Datenschutz`}</p>
    <p><strong parentName="p">{`Rechtswirksamkeit des Haftungsausschlusses`}</strong></p>
    <p>{`Der Haftungsausschluss ist als Teil des Internetangebotes zu betrachten, von dem aus auf diese Seite verwiesen wurde. Sofern Teile oder einzelne Formulierungen dieses Textes der geltenden Rechtslage nicht, nicht mehr oder nicht vollständig entsprechen sollten, bleiben die übrigen Teile des Dokumentes in ihrem Inhalt und ihrer Gültigkeit davon unberührt.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      